/** @jsx jsx */
import { ReactElement, useContext } from 'react';
import { Box, Flex, jsx } from 'theme-ui';
import { Container } from '../..';
import FooterLogo from './FooterLogo';
import FooterLinkListContainer from './linksList/FooterLinkListContainer';
import FooterLegalLinksContainer from './legalList/FooterLegalLinksContainer';
import { FooterProps } from './types';
import LanguageSelector from '../../components/languageSelector';
import createHeaderFooterUrls from '../../utils/createHeaderFooterUrls';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

export default function Footer({
  legal,
  links,
  isNotMainSite,
  site,
  isMinimal,
}: FooterProps): ReactElement {
  const { language } = useContext(I18nextContext);

  const sitePackage = site?.siteMetadata?.sitePackage;

  const getAppropriateURLs = menuItems => {
    menuItems.forEach(menuItem => {
      const { link, isExternalLink } = menuItem;
      if (!link) return;
      if (!isExternalLink)
        createHeaderFooterUrls(menuItem, true, sitePackage, language);
    });
  };

  legal.map(({ menuItems }) => getAppropriateURLs(menuItems));
  links.map(({ menuItems }) => getAppropriateURLs(menuItems));

  return (
    <Box
      as="footer"
      sx={{ bg: '#2E3841', flexShrink: isNotMainSite && 0 }}
      className={'scn-footer'}
    >
      <Container
        isBodyContainer={false}
        containerSx={{
          pb: [0, 0, 0, 0],
          pt: isMinimal ? [0, 0, 0] : [4, 4, 5],
        }}
      >
        <Box
          data-id="boxa"
          sx={{
            color: 'textSecondary',
            py: ['22px', '22px', '40px'],
          }}
        >
          {!isMinimal && (
            <Box
              data-id="boxb"
              sx={{
                display: ['flex', 'block', 'flex', 'flex'],
                flexFlow: 'row',
                flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
                justifyContent: [
                  'space-between',
                  'space-between',
                  'space-between',
                  'center',
                ],
                flexDirection: ['column', null, 'row', 'row'],
                pb: [0, 4],
              }}
              as="section"
            >
              <Box
                sx={{
                  mb: [4, 4, 4],
                  minWidth: ['100%', '30%', '20%'],
                  mt: ['-0.1rem', '-0.1rem', null, '-0.1rem'],
                  pr: '2rem',
                  mr: [0, 0, '40px', '20px'],
                }}
              >
                <FooterLogo />
              </Box>
              <Flex
                sx={{
                  flexWrap: ['wrap', 'wrap', 'nowrap'],
                  flex: 'row',
                  width: '100%',
                }}
                as="nav"
              >
                <FooterLinkListContainer
                  links={links.slice(0, 1)}
                  isFirstColumn={true}
                />
                <Box
                  sx={{
                    display: ['block', 'block', 'block', 'flex'],
                    flexWrap: 'wrap',
                    mb: ['2rem', '2rem', 0, 0],
                    flexGrow: [0, 0, 0, 1],
                  }}
                >
                  <FooterLinkListContainer links={links.slice(1)} />
                </Box>
              </Flex>
            </Box>
          )}
          <Flex
            sx={{
              borderColor: 'textSecondary',
              borderTop: isMinimal ? '' : '1px solid #646470',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
              fontSize: 1,
              pt: isMinimal ? [0] : [4],
            }}
            as="section"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexGrow: 1,
                mb: [4, 3, 2, 0],
                mt: ['2rem', '2rem', 0, 0],
                minWidth: '100px',
              }}
            >
              <LanguageSelector theme="dark" trigger="label" isSeoHidden />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row',
                flexGrow: 1,
                alignContent: 'center',
                justifyContent: 'flex-end',
                mb: [4, 3, 2, 0],
                mt: ['2rem', '2rem', 0, 0],
              }}
            >
              <FooterLegalLinksContainer legalLinks={legal} />
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
}
