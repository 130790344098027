/** @jsx jsx */
import { ReactElement } from 'react';
import { Box, jsx } from 'theme-ui';

import { FooterLinkListProps } from '../types';

export default function FooterLegalLinkList({ children, ...rest }: FooterLinkListProps): ReactElement {
  return (
    <Box
      sx={{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexFlow: 'row',
        alignContent: 'center',
        justifyContent: ['flex-start', 'flex-start', 'flex-end', 'flex-end'],
        flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
        flexGrow: 1,
        a: {
          m: 0,
          mr: ['1rem', '1rem', 0, 0],
          ml: [0, 0, '1rem', '1rem'],
          span: {
            mb: 0,
          },
        },
      }}
      {...rest}
      as="ul"
    >
      {children}
    </Box>
  );
}
