/** @jsx jsx */
import { ReactElement } from 'react';
import { jsx, ThemeUIStyleObject } from 'theme-ui';
import { FooterLinkProps } from './types';
import { openCookiePreferences } from '../../utils/openCookiePreferences';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { removeNodeLocaleFromSlug } from '@sprinklr/shared-lib/utils/removeNodeLocaleFromSlug';
import { Link } from '../../components/link';

const NEW_TAB_LINK_STYLES: ThemeUIStyleObject = {
  mb: ['1em'],
};

export default function FooterLink({
  children,
  isHeader,
  isExternalLink,
  isOpenNewTab,
  size,
  url,
  ...rest
}: FooterLinkProps): ReactElement {
  const isHeaderWithNoLink = isHeader && !url;
  const footerLinkSX: ThemeUIStyleObject = {
    borderBottomColor: 'transparent',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    color: 'textSecondary',
    fontSize: size || (isHeader ? 3 : 2),
    fontWeight: isHeader ? 'bold' : 'semiBold',
    lineHeight: '1.3rem',
  };

  const headerSX: ThemeUIStyleObject = {
    ...footerLinkSX,
    marginTop: 0,
  };

  const linkSX: ThemeUIStyleObject = {
    ...footerLinkSX,
    textUnderlinePosition: 'under',
    textDecoration: 'underline',
    textDecorationColor: 'transparent',
    transition: '.2s text-decoration-color',
    paddingBottom: '2px',
    ':hover': {
      textDecorationColor: '#fff',
      transition: '.2s text-decoration-color',
    },
  };

  const { language } = useI18next();
  const cleanUrl = removeNodeLocaleFromSlug(url);
  // Use cleaned URL for en, but use unmodified Contentful slug for other languages.
  const localeUrl = language === `en` || language === `en-US` ? cleanUrl : url;

  if (isOpenNewTab)
    return (
      <li sx={NEW_TAB_LINK_STYLES}>
        <a
          sx={linkSX}
          href={url}
          {...rest}
          target="_blank"
          rel="noreferrer noopener"
        >
          {children}
        </a>
      </li>
    );

  if (!!url && url.includes('info.sprinklr')) isExternalLink = true;

  const handleClick = e => openCookiePreferences(url, e);

  const linkElement = isHeaderWithNoLink ? (
    <p sx={headerSX}>{children}</p>
  ) : isExternalLink ? (
    <a sx={linkSX} href={url} onClick={e => handleClick(e)} {...rest}>
      {children}
    </a>
  ) : (
    <Link
      linkSx={linkSX}
      {...rest}
      href={localeUrl}
      disableLocalization={true}
      onClick={e => handleClick(e)}
    >
      {children}
    </Link>
  );

  return (
    <li sx={{ mb: !isHeaderWithNoLink ? ['1em'] : undefined }}>
      {linkElement}
    </li>
  );
}
