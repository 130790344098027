import { isRelativeURL } from '@sprinklr/shared-lib/utils/isRelativeURL';
import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

export default function createHeaderFooterUrls(
  linkItem,
  isFooter = false,
  sitePackage = 'website',
  locale = '',
) {
  if (isRelativeURL(linkItem?.link)) {
    let host = 'https://www.sprinklr.com';
    const originalLocale = locale;
    if (locale === 'en' || locale === 'it') {
      locale = '';
    }

    if (process.env.GATSBY_ENV !== 'production' && sitePackage === 'website') {
      if (isWindowDefined()) {
        host = window.location.origin;
      }
    }

    if (linkItem.link && linkItem.link !== '/') {
      linkItem.link = linkItem.link.replace(/\/?$/, '/');
    }

    locale &&
      linkItem.external &&
      (linkItem.link = `${host}/${locale}${linkItem.link}`);

    if (sitePackage === 'explore' && originalLocale === 'en') {
      linkItem.link = `${host}${locale}${linkItem.link}`;
    }
  }

  return linkItem;
}
