/** @jsx jsx */
import { ReactElement, ReactNode } from 'react';
import { jsx } from 'theme-ui';

import FooterLink from '../FooterLink';
import FooterLegalLinkList from './FooterLegalLinkList';

import { FooterLegalLinksContainerProps } from '../types';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function FooterLegalLinksContainer({
  legalLinks,
}: FooterLegalLinksContainerProps): ReactElement {
  const { t, language } = useI18next();

  if (!legalLinks.length) return null;

  const { menuItems } = legalLinks[0];

  menuItems.forEach(item => {
    item.label = t(item.label);
  });

  return (
    <FooterLegalLinkList orientation="horizontal">
      {menuItems
        .filter(({ isHeader, link }) => !(!isHeader && !link))
        .map(
          ({ label, link, isHeader, isExternalLink }): ReactNode => (
            <FooterLink
              key={label}
              sx={{
                fontSize: 1,
                fontWeight: 'medium',
                ml: '1rem',
                span: {
                  paddingBottom: 0,
                },
                textAlign: 'center',
              }}
              url={link}
              isHeader={isHeader}
              isExternalLink={isExternalLink}
            >
              {label}
            </FooterLink>
          )
        )}
    </FooterLegalLinkList>
  );
}
