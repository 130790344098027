/** @jsx jsx */
import { ReactElement, ReactNode, Fragment } from 'react';
import { Box, jsx } from 'theme-ui';

import FooterLinkList from './FooterLinkList';
import FooterLink from '../FooterLink';

import { LinkListContainerProps } from '../types';

export default function LinkListContainer({ links, isFirstColumn }: LinkListContainerProps): ReactElement {
  if (!links.length) return null;

  return (
    <Fragment>
      {links.map(({ menuItems, name }, i): ReactElement => {
        const filteredMenuItems = menuItems.filter(({ isHeader, heading, link }) => {
          const isAHeader = heading || isHeader;
          return isAHeader || !!link;
        });
        const headerItem = filteredMenuItems.find(({ isHeader, heading }) => isHeader || heading);
        const linkMenuItems = filteredMenuItems.filter(({ isHeader, heading }) => !isHeader && !heading);

        return (
          <Box
            key={`${name || i}-menu-group`}
            sx={{
              display: ['block', 'block', 'block', 'flex'],
              flexGrow: [1, 1, 1, isFirstColumn ? 0 : 1],
              width: ['100%', '100%', '100%', 'auto'],
              minWidth: '188px',
              mt: ['2rem', '2rem', 0],
              mr: 0,
              mb: isFirstColumn ? 0 : ['2rem', '2rem', '2rem', '1rem'],
              ':last-child': {
                '@media screen and (min-width: 1147px)': {
                  maxWidth: '50%',
                },
              },
            }}
          >
            <FooterLinkList orientation="vertical" isRowReverse={true} sectionTitle={headerItem?.label}>
              {linkMenuItems
                .filter(({ label }) => label)
                .map(({ heading, isHeader, isExternalLink, label, link, isOpenNewTab }, i): ReactNode => {
                  return (
                    <FooterLink
                      key={`${label}-${i}`}
                      url={link}
                      isHeader={heading || isHeader}
                      isExternalLink={isExternalLink}
                      isOpenNewTab={isOpenNewTab}
                      sx={{
                        maxWidth: !isFirstColumn && '160px',
                      }}
                    >
                      {label}
                    </FooterLink>
                  );
                })}
            </FooterLinkList>
          </Box>
        );
      })}
    </Fragment>
  );
}
