/** @jsx jsx */
import { ReactElement } from 'react';
import { Box, jsx, ThemeUIStyleObject } from 'theme-ui';

import { FooterLinkListProps } from '../types';

const LINKS_WRAPPER_STYLES: ThemeUIStyleObject = {
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexFlow: 'column',
  flexBasis: ['100%', '100%', '100%', 'auto'],
  minWidth: [0, 0, '160px', '160px'],
  maxWidth: ['auto', 'auto', '205px', '205px'],
  pb: [0, 0, 0, '10px'],
};

const SECTION_TITLE_STYLES: ThemeUIStyleObject = {
  color: 'textSecondary',
  fontSize: 3,
  fontWeight: 'bold',
  lineHeight: '1.3rem',
  mb: ['1em'],
};

export default function FooterLinkList({ children, isRowReverse, sectionTitle, ...rest }: FooterLinkListProps): ReactElement {
  return (
    <Box
      sx={{
        display: ['block', 'block', 'block', 'flex'],
        flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
        flexDirection: ['row', 'row', 'row', `${isRowReverse ? 'row-reverse' : 'row'}`],
        flexGrow: 3,
      }}
      {...rest}
    >
      <Box>
        <Box sx={SECTION_TITLE_STYLES} as="p">
          {sectionTitle}
        </Box>
        <Box sx={LINKS_WRAPPER_STYLES} as="ul">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
