import { isWindowDefined } from './isWindowDefined';

export const openCookiePreferences = (url: string, e: any) => {
  if (url.includes('#cookie-preferences')) {
    e.preventDefault();
    // @ts-ignore: window.Osano is injected in the browser
    isWindowDefined() &&
      window.Osano &&
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }
};
