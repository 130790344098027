/** @jsx jsx */
import { ReactElement } from 'react';
import { jsx, Text } from 'theme-ui';
import { Link } from '@sprinklr/shared-lib';
import SprinklrLogo from '../../assets/svgs/sprinklr-footer-logo.svg';
import { FooterLogoProps } from './types';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const LOGO_SEO_LABEL_TEXT = 'Sprinklr Website Home';
const LOGO_SEO_LABEL_STYLES = { display: 'none' };

export default function FooterLogo({ url = '/' }: FooterLogoProps): ReactElement {
  const { t } = useTranslation();

  if (process.env.GATSBY_PACKAGE !== 'website') {
    url = 'https://www.sprinklr.com/';
  }

  return (
    <Link href={url} variant={'unstyled'}>
      {/* TODO: Copy and translations for aria-label */}
      <SprinklrLogo aria-label={t('Sprinklr logo')} sx={{ display: 'block', maxWidth: '140px', height: '40px', width: '100%' }} />
      <Text as="span" sx={LOGO_SEO_LABEL_STYLES}>
        {t(LOGO_SEO_LABEL_TEXT)}
      </Text>
    </Link>
  );
}
